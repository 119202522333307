import { ENDPOINTS } from "../../../api/identity-service";
import useIdentityApi from "../../../api/utils/identity-hook.utils";
import { UserEntry } from "../pages/users/admin-users.component";

export function useUsers(doFetch: boolean = true, take: number = 50) {
    const result = useIdentityApi<UserEntry>(ENDPOINTS.USERS, doFetch, take);

    return {
        meta: result.meta,
        users: result.data,
        createUser: result.create,
        updateUser: result.update,
        removeUser: result.remove,
        dataLoading: result.isLoading,
        nextPage: result.nextPage,
        previousPage: result.previousPage,
        setPage: result.setPage,
    };
}